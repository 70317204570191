/**
 * Abstract class NFBNormanCarousel.
 *
 * Generic carousel displaying work cards via NFBDisplayWorkCard component.
 */

import { LitElement, html, nothing, css } from 'lit'
import { property, state } from 'lit/decorators.js'

// @ts-expect-error Lit Sass import
import carouselSlotStyle from '@web-nfb/frontend-static/design-system/wc/carousel/NFBCarouselSlot.sass?lit'
// @ts-expect-error Lit Sass import
import cardSlotStyle from '@web-nfb/frontend-static/design-system/wc/card/NFBCardSlot.sass?lit'

import { store, RootState } from '@src/js/redux/store'
import normanPaths from '@src/js/helpers/paths'
import { GTMSelectWork, ObjectValues } from '@src/js/types/GTM'
import { GTM_EVENTS, GTM_LIST_CATEGORY, GTM_LIST_TYPE } from '@src/js/constants'
import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'
import { WorkCard } from '@src/js/redux/worksReducer'
import { stringToBooleanConverter } from '@web-nfb/frontend-static/design-system/wc/_utils'
import { Unsubscribe } from 'redux/dist/redux'

export type ExtraCarouselGTMAttrs = {
  nfb_list_category?: ObjectValues<typeof GTM_LIST_CATEGORY>
  nfb_list_id?: string
  nfb_detail?: string
}

export default abstract class NFBNormanCarousel extends LitElement {
  @property({ converter: stringToBooleanConverter, attribute: 'show-title' })
  showTitle = true

  @property({ type: String, attribute: 'see-more-link' })
  seeMoreLink = ''

  @property({ converter: stringToBooleanConverter, attribute: 'show-see-more-card' })
  showSeeMoreCard = false // Show see more link as card. No effect if seeMoreLink is null.

  @property({ converter: stringToBooleanConverter, attribute: 'is-dark' })
  isDark = false

  @property({ converter: stringToBooleanConverter, attribute: 'is-clothesline' })
  isClothesLine = false

  @state()
  list: WorkCard[] | null = null

  _unsuscribe: Unsubscribe
  _rootState: RootState
  _title: string | null = null
  _commonGTMAttrs: GTMSelectWork
  _includeProgressBar = false

  _status: 'idle' | 'loading' | 'succeeded' = 'idle'

  static styles = [carouselSlotStyle, cardSlotStyle, css`
    .nfb-norman-carousel__spinner-container {
      min-height: 240px;
    }

    nfb-carousel::part(carousel) {
      background-color: transparent;
    }

    nfb-history-progress-bar::part(nfb-progress-bar) {
      position: absolute;
    }

    nfb-work-page-legend::part(container) {
      margin-top: 0;
      margin-bottom: 0;
    }

  `]

  /** Lit lifecycle functions */

  constructor () {
    super()
    this.stateChanged = this.stateChanged.bind(this)

    this.initialFetch()
  }

  connectedCallback (): void {
    super.connectedCallback()
    this._unsuscribe = store.subscribe(this.stateChanged)

    this._title = this.getTitle()

    this._commonGTMAttrs = {
      event: GTM_EVENTS.SELECT_WORK,
      nfb_list_type: GTM_LIST_TYPE.CARROUSEL,
      nfb_list_name: this._title,
      ...this.getExtraGTMAttrs()
    }
  }

  async stateChanged () {
    this._rootState = store.getState()
    if (this.list === null && this._status === 'idle') {
      // Start fetching works for carousel.
      this._status = 'loading'
      const works = await this.getWorks()
      if (works) {
        // Works where fetched successfully
        // (works is either a list of works or an empty list).
        this.list = works
        this._status = 'succeeded'
      } else {
        // Works is null, the store wasn't ready.
        // Allows getWorks to be called again on the state change.
        this._status = 'idle'
      }
    }
  }

  /** Abstract function: must be implemented in derived class */

  /**
   * Contains the internal logic of getting works.
   * @returns
   * - A list of works to be displayed in the carousel if works could be fetched
   * - Null if the store state wasn't yet ready for the works to be fetched
   * - An empty list if works where fetched successfully but none could be found
   */
  abstract getWorks (): Promise<WorkCard[] | null>

  /**
   * @returns the title of the carousel, used in GTM and displayed
   * as carousel title if show-title = true
   */
  abstract getTitle (): string

  /**
   * @return extra carousel-specific GTM attributes to be added to standard GTM attributes:
   * - nfb_list_category
   * - nfb_list_id
   * - nfb_detail
   */
  abstract getExtraGTMAttrs (): ExtraCarouselGTMAttrs

  /** Other function to override if necessary */

  /**
   * Will be called in constructor, if the carousel requires the store
   * to fetch data before beginning its life cycle.
   */
  initialFetch () { }

  /**
   *
   * @param work: A film, a series, a collection.
   * @returns the context work for the work, if it's a film
   * (ex: the series or collection the film is a part of).
   * Context work will be used for the thumbnail and the link of the card.
   */
  getContextWorkForWork (work: WorkCard): WorkCard | null { return null }

  /** Component functions */

  getCard (work: WorkCard, index: number) {
    const contextWork = this.getContextWorkForWork(work)
    const url = normanPaths[contextWork?.category ?? work.category]

    const gtmAttrs: GTMSelectWork = {
      ...this._commonGTMAttrs,
      nfb_version_title: work.title,
      nfb_detail: (index + 1).toString()
    }

    return html`
      <li slot="card" class="nfb-carousel__card" id="${index}" data-index="${index}">
        <nfb-display-work-card
          thumbnail-path="${contextWork?.thumbnail ?? work.thumbnail}"
          registry-id="${work.id}"
          include-progress-bar="${this._includeProgressBar ?? nothing}"
          gtm-attributes="${JSON.stringify(gtmAttrs)}"
          href="${url.replace('{slug}', contextWork?.slug ?? work.slug)}"
          is-dark=${this.isDark ?? nothing}
          secondary-variant=${this.isClothesLine ? 'no-border' : nothing}
          card-type="carousel"
        >
        </nfb-display-work-card>
      </li>`
  }

  getSeeMoreCard (index: number) {
    if (!this.list?.length || !this.showSeeMoreCard || !this.seeMoreLink) return nothing

    return html`
      <li slot="card" class="nfb-carousel__card" id="${this._title}-card-${index}" data-index="${index}">
        <nfb-see-more
          data-ui-el="carousel-card"
          href="${this.seeMoreLink ?? nothing}"
          text="See More"
          is-clothesline="${this.isDark ?? nothing}"
          is-dark="${this.isDark ?? nothing}"
        ></nfb-see-more>
      </li>
    `
  }

  getCarousel () {
    if (!this.list?.length) return nothing

    return html`
      <nfb-carousel
        id="${`${this._title}-carousel`.toLowerCase()}"
        data-cy="${`${this._title}-carousel`.toLowerCase()}"
        title="${this.showTitle ? this._title : nothing}"
        skip-list="${window.gettext('Skip This List')}"
        aria-label-previous="${window.gettext('See previous')} ${this.title}"
        aria-label-next="${window.gettext('See next')} ${this.title}"
        is-dark=${this.isDark ?? nothing}
        see-more-link=${this.seeMoreLink && !this.showSeeMoreCard ? this.seeMoreLink : nothing}
        is-clothesline=${this.isClothesLine ?? nothing}
      >
        ${this.list.map((work, index) => this.getCard(work, index))}
        ${this.getSeeMoreCard(this.list.length)}
      </nfb-carousel>
    `
  }

  render () {
    if (this.list === null) {
      return html`
        <div class="nfb-norman-carousel__spinner-container">
          <nfb-spinner
            is-dark="${this.isDark ?? nothing}"
            is-loading="true"
          ></nfb-spinner>
        </div>
      `
    }

    return this.getCarousel()
  }
}
