import { LitElement, html, nothing, css } from 'lit'
import { customElement, state } from 'lit/decorators.js'

import { GTMSelectWork } from '@src/js/types/GTM'
import { GTM_EVENTS, GTM_LIST_CATEGORY, GTM_LIST_TYPE } from '@src/js/constants'
import { WorkCard } from '@src/js/redux/worksReducer'

// @ts-expect-error Lit Sass import
import gridListStyle from '@web-nfb/frontend-static/design-system/sass/layout/grid-list.sass?lit'

@customElement('nfb-grid-list')
export default abstract class NFBGridList extends LitElement {
  @state()
  list: WorkCard[] | null = null

  static styles = [gridListStyle, css`
    .nfb-grid-list__container {
      min-height: 200px;
    }`
  ]

  async connectedCallback (): Promise<void> {
    super.connectedCallback()

    await this.updateComplete
    this.list = await this.getElements()
  }

  disconnectedCallback (): void {
    super.disconnectedCallback()
  }

  /** Abstract function: must be implemented in derived class */
  /**
   * Contains the internal logic of getting elements.
   * @returns a list of elements to be displayed in the carousel
   */
  abstract getElements (): Promise<WorkCard[] | any[]>

  /**
   * @returns the title of the list, used in GTM
   */
  abstract getTitle (): string

  /**
   * @returns the list id of the list, used in GTM
   */
  abstract getListId (): string

  render () {
    return html`
    <div class="nfb-grid-list__container">
      ${this.list ? nothing : html`<nfb-spinner is-loading="true" size="sm"></nfb-spinner>`}
      <div class="nfb-grid-list">
    ${this.list?.map((work, index) => {
    const gtmAttrs: GTMSelectWork = {
      event: GTM_EVENTS.SELECT_WORK,
      nfb_list_name: this.getTitle(),
      nfb_list_id: this.getListId(),
      nfb_list_type: GTM_LIST_TYPE.GRID,
      nfb_list_category: GTM_LIST_CATEGORY.PROGRAMMING,
      nfb_detail: (index + 1).toString()
    }

    return html`
      <nfb-display-work-card
        registry-id=${work.id}
        bookmark-button-type="add"
        gtm-attributes="${JSON.stringify(gtmAttrs)}"
      >
      </nfb-display-work-card>`
  })}
      </div>
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'nfb-grid-list': NFBGridList
  }
}
