/**
 * Class NFBContinueWatchingCarousel.
 *
 * Extends abstract class NFBNormanCarousel.
 */

import { customElement } from 'lit/decorators.js'

import { selectContinueWatching, WorkHistory } from '@src/js/redux/workHistoryReducer'
import { fetchContinueWatchingWorks, selectWorkById, selectWorkByIdList, WorkCard } from '@src/js/redux/worksReducer'
import { store } from '@src/js/redux/store'
import { GTM_LIST_CATEGORY } from '@src/js/constants'
import '@src/js/wc/work-page/NFBWorkPageCreator'
import '@src/js/wc/work-page/NFBWorkPageLegend'

import NFBNormanCarousel, { ExtraCarouselGTMAttrs } from './NFBNormanCarousel'

@customElement('nfb-continue-watching-carousel')
export default class NFBContinueWatchingCarousel extends NFBNormanCarousel {
  _includeProgressBar: boolean = true
  _workHistory: WorkHistory[]

  initialFetch (): void {
    store.dispatch(fetchContinueWatchingWorks())
  }

  getTitle (): string {
    return window.pgettext('Continue watching carousel', 'Continue watching')
  }

  getExtraGTMAttrs (): ExtraCarouselGTMAttrs {
    return {
      nfb_list_category: GTM_LIST_CATEGORY.CONTINUE,
      nfb_list_id: 'continueWatching'
    }
  }

  async getWorks (): Promise<WorkCard[] | null> {
    this._rootState = store.getState()
    this._workHistory = selectContinueWatching(this._rootState.workHistory)
    return selectWorkByIdList(this._rootState.works, this._workHistory.map(h => h.registry_id))
  }

  getContextWorkForWork (work: WorkCard): WorkCard | null {
    const relatedHistory = this._workHistory.find(h => h.registry_id === work.id)

    if (!relatedHistory?.work?.registry_id) {
      return null
    }

    const contextWork = selectWorkById(this._rootState.works, relatedHistory.work?.registry_id)
    return contextWork ?? null
  }
}
