import { PayloadAction } from '@reduxjs/toolkit'
import { setDisplayedSeasonNumber } from '@src/js/redux/seriesPageReducer'

const seriesMiddleware = (store: any) => (next: any) => async (action: PayloadAction<any>) => {
  if (action.type.match('workPage/setDisplayedRegistryId/fulfilled')) {
    const seriesPageState = store.getState().seriesPage
    // When changing displayed registry id, also change displayed season in episodes list if necessary.
    const registryId = action.payload as number
    let seasonNumber: string | null = null

    const episodesListEntries = Object.entries(seriesPageState.episodesList)
    for (const [number, list] of episodesListEntries) {
      if ((list as number[]).includes((registryId))) {
        seasonNumber = number
        break
      }
    }

    if (seasonNumber && seriesPageState.displayedSeasonNumber !== seasonNumber) {
      store.dispatch(setDisplayedSeasonNumber(parseInt(seasonNumber)))
    }
    next(action)
  } else {
    next(action)
  }
}

export default seriesMiddleware
