import uniqBy from 'lodash/uniqBy'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { PublicApiV5Client } from '@src/js/ApiClient'
import { fetchAllContinueWatching, selectContinueWatchingAndParentWorkIds } from '@src/js/redux/workHistoryReducer'
import { AppDispatch, RootState } from '@src/js/redux/store'
import { fetchFilmHistoryByRegistryIds } from '@src/js/redux/filmHistoryReducer'

const publicApiV5Client = PublicApiV5Client.instance

export interface WorkDirector {
  first_name: string,
  last_name: string,
  slug: string
}

export interface WorkCard {
  id: number
  title: string
  slug: string
  duration: number
  year: number
  thumbnail: string
  label: string
  directors: WorkDirector[]
  producers: WorkDirector[]
  category: 'film'|'series'|'collection'
  description: string
  tagline: string
  cc: boolean
  dv: boolean
  rating: string
  coming_soon: string
  is_new_release: boolean
  leaving_soon: string
  title_treatment: string
  key_art_square: string
  key_art_horizontal: string
  story_art_horizontal: string
  story_art_vertical: string
  story_art_square: string
  geoblocked: boolean
  warning: string
  availability?: {
    resource_url: string | null
    buy: boolean
    dvd: boolean
    is_public: boolean
    rent: boolean
  }
  subjects?: string[]
  credits_timecodes?: {
    opening: number | null
    ending: number | null
  }
  year_max?: number
  year_min?: number
  episodes_count?: number
  seasons_count?: number
  genres?: string[]
}

export interface APIWorksResponse {
  items: WorkCard[],
  meta: {
    mixed_media: {
      thumbnail_url: {
        film: string
      }
    }
  }
}

export interface APITypeResponse {
  item: {
    subjects: [
      {
        category_type: string
        slug: string
        name: string
        children: [
          {
            category_type: string | null,
            slug: string
            name: string
          }
        ]
      },
    ]
  }
}

export const basicIncludeFields = [
  'slug', 'duration', 'year', 'thumbnail', 'directors', 'cc', 'rating', 'coming_soon',
  'is_new_release', 'leaving_soon', 'availability', 'geoblocked', 'producers', 'year_max',
  'year_min', 'seasons_count', 'episodes_count'
]

const detailedIncludeFields = [...basicIncludeFields, 'tagline', 'description', 'title_treatment', 'key_art_square', 'subjects', 'genres'
]

export interface WorksState {
  list: WorkCard[] | null,
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  thumbnailPattern: string
}

const initialState: WorksState = {
  list: null,
  status: 'idle',
  thumbnailPattern: ''
}

interface GetWorksParams {
  idList: number [],
  collection?: 'nfb/public' | 'gc/gc-films',
  includeFields?: string []
}

export const fetchByRegistryIds = createAsyncThunk<
  APIWorksResponse, GetWorksParams
>('works/fetch', async ({ idList, collection = 'nfb/public', includeFields = detailedIncludeFields }) => {
  const response = await publicApiV5Client.call({
    method: 'GET',
    url: '/works',
    params: {
      registry_ids: idList.join(','),
      include_series_episodes: true,
      include_fields: includeFields,
      locale: window.LOCALE,
      size: idList.length,
      collection
    }
  }) as AxiosResponse

  if (response.status !== 200) {
    throw new Error(`Response status: ${response.status}`)
  }

  return await response.data
})

export const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {
    addWorkToList: (state, action: PayloadAction<WorkCard[]>) => {
      state.list = uniqBy([
        ...action.payload,
        ...(state.list ?? [])
      ], 'id')
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchByRegistryIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchByRegistryIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.list = uniqBy([
          ...action.payload.items,
          ...(state.list ?? [])
        ], 'id')
        state.thumbnailPattern = action.payload.meta.mixed_media.thumbnail_url.film
      })
      .addCase(fetchByRegistryIds.rejected, (state, action) => {
        state.status = 'failed'
        console.log(action.error)
        // @todo: handle errors
      })
  },
  selectors: {
    selectWorkById: (state: WorksState, id: number) => {
      if (!state.list) return null

      return state.list.find(work => work.id === id) ?? null
    },
    selectWorkByIdList: (state: WorksState, idList: number[]): WorkCard[] | null => {
      if (!state.list) return null

      if (!idList) {
        return []
      }
      const works = state.list.filter(work => idList.includes(work.id))
      // Return works in the same order as idList
      return works.sort((a, b) => idList.indexOf(a.id) - idList.indexOf(b.id))
    }
  }
})

export const fetchContinueWatchingWorks = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch,
    state: RootState
  }>('works/fetchContinueWatching', async (_, thunkAPI) => {
    await thunkAPI.dispatch(fetchAllContinueWatching())
    const state: RootState = thunkAPI.getState()
    const idListToFetch: number[] = selectContinueWatchingAndParentWorkIds(state.workHistory)
    await thunkAPI.dispatch(fetchFilmHistoryByRegistryIds(idListToFetch))
    await thunkAPI.dispatch(fetchByRegistryIds({ idList: idListToFetch }))
  })

export const { addWorkToList } = worksSlice.actions

export const { selectWorkById, selectWorkByIdList } = worksSlice.getSelectors()

export default worksSlice.reducer
