import { WorkHistory, postHistory } from '@src/js/redux/workHistoryReducer'
import { AppDispatch } from '@src/js/redux/store'
import { sortByTimestamp } from '@src/js/helpers/sort'

export const fetchAllFromSessionStorage = (key: string): WorkHistory[] => {
  const unparsedSessionHistory: string|null = window.sessionStorage.getItem(key)
  const parsedSessionHistory = unparsedSessionHistory ? JSON.parse(unparsedSessionHistory) : []
  return parsedSessionHistory.sort(sortByTimestamp)
}

export const postSessionStorage = async (key: string, thunkAPI: {dispatch: AppDispatch}) => {
  const historySession = fetchAllFromSessionStorage(key)
  if (historySession.length > 0) {
    for (const item of historySession) {
      await thunkAPI.dispatch(postHistory(item))
    }
    window.sessionStorage.removeItem(key)
  }
}
