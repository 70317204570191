export const GTM_EVENTS = {
  ADD_TO_MYLIST: 'nfb_add_to_mylist',
  REMOVE_FROM_MYLIST: 'nfb_remove_from_mylist',
  SHARE: 'share',
  TAB: 'nfb_tab',
  SELECT_WORK: 'nfb_select_work',

  // Ecomm
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',

  //  Player
  WORK_START: 'nfb_work_start',
  VIDEO_START: 'video_start',
  WORK_RESUME: 'nfb_work_resume',
  WORK_PROGRESS: 'nfb_work_progress',
  VIDEO_PROGRESS: 'video_progress',
  WORK_COMPLETE: 'nfb_work_complete',
  VIDEO_COMPLETE: 'video_complete',
  LANG_SWITCH: 'nfb_lang_switch',
  CAST_START: 'nfb_cast_start'
} as const

export const GTM_LIST_TYPE = {
  CARROUSEL: 'carrousel',
  LIST: 'list',
  GRID: 'grid'
} as const

export const GTM_LIST_CATEGORY = {
  PROGRAMMING: 'programming', // thematic carrousels published by the content team
  SUGGESTION: 'suggestions', // suggested films on work page
  EXTRAS: 'extras', // extras on work page
  TRENDING: 'trending', // trending carrousel
  CONTINUE: 'continue_watching', // continue watching carrousel
  ARTICLES: 'articles', // @todo: blog carrousel
  USER_LIST: 'user_list', // @todo: mylist carrousel
  SEARCH_RESULT: 'search_results',
  EXPLORE: 'explore', // explore by
  SPECIAL_COLLECTION: 'special_collection', // indigenous collection
  PLAYLIST: 'playlist',
  PART_OF: 'part_of' // film or episode list on work page
} as const

export const GTM_CONTENT_TYPE = {
  FILM: 'film',
  SERIES: 'series',
  COLLECTION: 'collection',
  PLAYLIST: 'playlist'
} as const

export const GTM_VIDEO_CAST_TYPE = {
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable'
} as const

export const GTM_VIDEO_CASTING_TYPE = {
  ON: 'on',
  OFF: 'off',
  NOT_APPLICABLE: 'n/a'
} as const

export const GTM_VIDEO_CAST_TECH_TYPE = {
  CHROMECAST: 'chromecast',
  AIRPLAY: 'airplay',
  NOT_APPLICABLE: 'n/a'
} as const

export const GTM_LANG_CONTENT_TYPE = {
  CC: 'cc',
  DV: 'dv',
  INTERFACE: 'interface'
} as const

export const GTM_ECOMMERCE_ITEM_DETAIL = {
  CURRENCY: 'CAD',
  AFFILIATION: 'NFB-ONF',
  BRAND: 'ONF/NFB'
} as const
