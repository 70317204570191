import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { ApiV4Client } from '@src/js/ApiClient'
import { WorkCard } from '@src/js/redux/worksReducer'
import { ThunkAPI } from '@src/js/redux/store'

const apiV4Client = ApiV4Client.instance

interface Rental {
  expiration_date: string
  film_jacket: string
  film_slug: string
  film_title: string
}

export interface UserState {
  isConnected: boolean
  rentals: Rental[]
}

const initialState: UserState = {
  isConnected: false,
  rentals: []
}

export const fetchRentals = createAsyncThunk<
  Rental[], void, ThunkAPI
>('user/fetchRentals', async (_, thunkAPI) => {
  if (thunkAPI.getState().user.isConnected) {
    const response = await apiV4Client.call({
      method: 'GET',
      url: '/accounts/unused/active-rentals'
    })

    return (await response.data).data
  }

  return []
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUser (state) {
      state.isConnected = window.USER_CONNECTED
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchRentals.fulfilled, (state, action) => {
      state.rentals = action.payload
    })
  },
  selectors: {
    selectActiveRentalForWork: (state: UserState, work: WorkCard) => {
      return state.rentals.find(r => r.film_slug === work.slug)
    }
  }
})

export const {
  initUser
} = userSlice.actions

export const {
  selectActiveRentalForWork
} = userSlice.getSelectors()

export default userSlice.reducer
