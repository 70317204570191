import AccordionManager from '@web-nfb/frontend-static/design-system/script/elements/AccordionManager'
import CollapsableManager from '@web-nfb/frontend-static/design-system/script/elements/CollapsableManager'
import CookiesBannerManager from '@web-nfb/frontend-static/design-system/script/elements/CookiesBannerManager'
import CarouselsManager from '@web-nfb/frontend-static/design-system/script/elements/CarouselsManager'
import CardManager from '@web-nfb/frontend-static/design-system/script/elements/CardManager'
import DropdownManager from '@web-nfb/frontend-static/design-system/script/elements/DropdownManager'
import FormManager from '@web-nfb/frontend-static/design-system/script/elements/FormManager'
import LeftNavManager from '@web-nfb/frontend-static/design-system/script/elements/LeftNavManager'
import { initTooltips } from '@web-nfb/frontend-static/design-system/script/elements/TooltipManager'
import { initRibbons, destroyRibbons } from '@web-nfb/frontend-static/src/js/custom/modules/m-ribbon.js'

import '@web-nfb/frontend-static/design-system/wc/badge/NFBBadge'
import '@web-nfb/frontend-static/design-system/wc/button/NFBButton'
import '@web-nfb/frontend-static/design-system/wc/card/NFBCard'
import '@web-nfb/frontend-static/design-system/wc/card/NFBCarouselCard'
import '@web-nfb/frontend-static/design-system/wc/carousel/NFBCarousel'
import '@web-nfb/frontend-static/design-system/wc/confirmation/NFBConfirmation'
import '@web-nfb/frontend-static/design-system/wc/icon/NFBIcon'
import '@web-nfb/frontend-static/design-system/wc/inlineMessage/NFBInlineMessage'
import '@web-nfb/frontend-static/design-system/wc/legend/legendIcons/NFBLegendIcons'
import '@web-nfb/frontend-static/design-system/wc/legend/legendItem/NFBLegendItem'
import '@web-nfb/frontend-static/design-system/wc/spinner/NFBSpinner'
import '@web-nfb/frontend-static/design-system/wc/thumbnail/NFBThumbnail'
import '@web-nfb/frontend-static/design-system/wc/seeMore/NFBSeeMore'

import '@norman/templates/elements/atecna_poll/NFBAtecnaPoll'

import '@src/js/wc/NFBAddToMyList'
import '@src/js/wc/NFBGridList'
import '@src/js/wc/NFBHistoryProgressBar'
import '@src/js/wc/NFBPill'
import '@src/js/wc/cards/NFBDisplayWorkCard'
import '@src/js/wc/carousels/NFBContinueWatchingCarousel'

// Expose CarouselsManager globally
// to initiate objects in non-module JS files (ex: for the blog carousel)
window.CarouselsManager = CarouselsManager
window.CardManager = CardManager

export default class App {
  accordionManager: AccordionManager | undefined
  collapsableManager: CollapsableManager | undefined
  cookiesBannerManager: CookiesBannerManager | undefined
  carouselsManager: CarouselsManager | undefined
  cardManager: CardManager | undefined
  dropdownManager: DropdownManager | undefined
  formManager: FormManager | undefined
  leftNavManager: LeftNavManager | undefined

  init () {
    console.log('Init app')

    this.cookiesBannerManager = new CookiesBannerManager()
    this.cookiesBannerManager.init()

    this.carouselsManager = new CarouselsManager()
    this.carouselsManager.init()

    this.cardManager = new CardManager()
    this.dropdownManager = new DropdownManager({ selector: '[data-ui-el="nfb-dropdown-trigger"]' })
    this.dropdownManager.init()

    this.accordionManager = new AccordionManager()
    this.collapsableManager = new CollapsableManager()

    this.formManager = new FormManager()
    this.formManager.init()

    try {
      this.leftNavManager = new LeftNavManager()
      this.leftNavManager.init()
    } catch {}

    initTooltips()
    initRibbons()

    this.setContentAsLoaded()
  }

  setContentAsLoaded = () => {
    const layout = document.querySelector('[data-ui-el="main-layout"]') as HTMLElement
    if (layout) { layout.dataset.uiLoaded = true.toString() }
  }

  destroy () {
    console.log('Destroy app')
    this.cookiesBannerManager?.destroy()
    this.carouselsManager?.destroy()
    this.cardManager?.destroy()
    this.dropdownManager?.destroy()

    destroyRibbons()
  }
}
